import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar } from '@material-ui/core';
import logo from '../img/bar_logo.png';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root:{
        display: 'flex',
        opacity: 0,
        animationDelay: '1s',
        animation: '$headerReveal 0.75s ease-in-out forwards',
    },
    appbar:{
        background:'none',
    },
    appbarTitle:{
        flexGrow: '1',
    },
    logo:{
        // background: 'red',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(25%)',
        height: 'auto',
        left: '5vw',
        [theme.breakpoints.up('xs')]:{
            maxWidth: '20%',
            maxHeight: '5vh',
        },
        [theme.breakpoints.down('sm')]:{
            maxWidth: '100%',
            // minHeight: '10vh',
        },
    },
    logoGridItem: {
        // textAlign: 'justify',
        // textJustify: 'left',
    },
    contactGridItem: {
        // textAlign: 'justify',
        // textJustify: 'right',
    },
    appbarLinks:{
        [theme.breakpoints.up('xs')]:{
            fontSize: '1rem',
        },
        [theme.breakpoints.down('xs')]:{
            fontSize: '0.75rem',
        },
        [theme.breakpoints.up('lg')]:{
            fontSize: '1.25rem',
        },
        color: '#fff',
        textShadow: '2px 2px 2px #0B2C66',
        position: 'absolute',
        // marginRight: '5vw',
        right: '5vw',
    },
    appbarWrapper:{
        // width:"80%",
        margin: "50 auto",
    },
    linkBox: {
        // background: 'rgba(11,44,102,0.2)',
        borderRadius: 3,
        border: 0,
        [theme.breakpoints.up('xs')]:{
            padding: '5px',
        },
        [theme.breakpoints.down('xs')]:{
            padding: '0',
        },
        // boxShadow: '0 1px 1px 2px #000',
    },
    headerButton: {
        // position: 'absolute',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: "2rem",
        color: 'white',
        background: 'rgba(255,255,255,0.05)',
        borderRadius: '100',
        width: '100%',
        // bottom: '5vh',
    },
    linkButton: {
        // background: 'rgba(11,44,102,0.2)',
        color: 'white',
        [theme.breakpoints.up('xs')]:{
            fontSize: '1rem',
        },
        [theme.breakpoints.down('xs')]:{
            fontSize: '0.6rem',
        },
        [theme.breakpoints.up('lg')]:{
            fontSize: '1rem',
        },
        // fontFamily: 'Nunito',
        fontWeight: 'Bold',
    },
    "@keyframes headerReveal":{
        from:{
          opacity: 0
        },
        to:{
          opacity: 1
        }
      },
}));

export default function Header() {
    const classes = useStyles()

    return (
        <div className={classes.root} id="header">
            <AppBar className={classes.appbar} elevation={0}>
                <Toolbar className={classes.appbarWrapper}>
                    <Grid container>
                        <Grid item xs={6} className={classes.logoGridItem}>
                                <img src={logo} alt="" className={classes.logo}/>
                        </Grid>
                        <Grid item xs={6} className={classes.contactGridItem}></Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </div>
    );
}
