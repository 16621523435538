import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import logoLetters from '../img/logo_letters.png';

const useStyles = makeStyles((theme) => ({
    intro:{
        background: 'lightblue',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        animationDelay: '1s',
        animation: '$introMotion 2s ease-in-out forwards'
    },
    lines:{
        overflow: 'hidden',
        height: '20vw',
        width: '30vw',
        position: 'relative',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        // background: 'white',
    },
    logoLetters:{
        height: 'auto',
        width: '20vw',
        left: '50%',
        top: '50%',
        position: 'relative',
        transform: 'translate(-50%,-50%)',
        // transform: 'translate(-50%,-200%)',
        // animation: '$logoIntro 1.5s ease-in-out forwards',
    },
    "@keyframes introMotion":{
        from:{
        //   transform: 'translateY(0)'
            opacity: 1,
            pointerEvents: 'all'
        },
        to:{
        //   transform: 'translateY(100vh)'
            opacity: 0,
            pointerEvents: 'none'
        }
    },
    "@keyframes logoIntro":{
        '0%':{
            transform: 'translate(-50%,-200%)',
            opacity: 1
        },
        '50%':{
            transform: 'translate(-50%,-50%)',
            opacity: 1,
        },
        '100%':{
            transform: 'translate(-50%,-50%)',
            opacity: 0,
        },
    },
}))

export default function Intro(props) {
    const classes = useStyles();



    return (
        <>
            <div className={classes.intro}>
                <div className={classes.lines}>
                    <img src={logoLetters} alt="" className={classes.logoLetters}/>
                </div>
            </div>
        </>
    )
}
