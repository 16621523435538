import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Paper , Button, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
// import Modal from '@material-ui/core/Modal';
// import Backdrop from '@material-ui/core/Backdrop';
// import Fade from '@material-ui/core/Fade';
// import ClearIcon from '@material-ui/icons/Clear';
import InstagramIcon from '@material-ui/icons/Instagram';

const useStyles = makeStyles((theme) => ({
    mainGrid: {
        [theme.breakpoints.up('md')]:{
            bottom: '10vh',
        },
        [theme.breakpoints.down('md')]:{
            bottom: '10vh',
            paddingTop: 'min(10vh, 50px)',
        },
        [theme.breakpoints.down('sm')]:{
            paddingTop: 'min(10vh,50px)',
            bottom: '10vh',
        },

        // background: 'black',
        position: 'absolute',
        // top: '50vh',
        // bottom: '10vh',     // Change this to move text and buttons up
        zIndex: 1,
        // maxHeight: '50vh',
    },
    gridItem:{
        minHeight: '25vh',
    },
    paper:{
        height:'100%',
        width:'100%',
        margin: '0 auto',
        textAlign: "center",
        outline: 0,
        backgroundColor: 'rgba(20,30,45,0.5)',
        padding: '2',
        fontFamily: 'Montserrat, sans-serif',
        
        color: 'white',
        [theme.breakpoints.up('xs')]:{
            fontSize: '1.75rem',
            fontWeight: '700',
          },
        [theme.breakpoints.down('xs')]:{
            fontSize: '1rem',
            fontWeight: '700',
        },
    },
    paragraphText: {
        fontWeight: '400',
        // fontSize: '0.75rem',
        textAlign: 'justify',
        textJustify: 'left',
        // marginTop: '5vh',
        margin: 'auto',
        [theme.breakpoints.up('xs')]:{
            fontSize: '1rem',
            fontWeight: '400',
            width: '90%',
          },
        [theme.breakpoints.down('xs')]:{
            fontSize: '1rem',
            fontWeight: '400',
            width: '90%',
        },
        maxHeight: '25vh',
        overflow: 'scroll',
        overflowX: 'hidden',
    },
    spacing: {
        marginTop: "1vh",
        minHeight: '0vh',
        // background: 'red',
    },
    button: {
        // position: 'absolute',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: "1rem",
        color: 'white',
        background: 'rgba(255,255,255,0.05)',
        borderRadius: '100',
        width: '100%',
        // bottom: '5vh',
    },
    actualAbout:{
        opacity: 0,
        animationDelay: '2s',
        animation: '$aboutTransition 0.75s ease-in-out forwards',
        transformOrigin: 'top',
    },
    boldLetters:{
        fontWeight: 'bold',
        textAlign: 'center',
        fontFamily: 'Montserrat, sans-serif',
    },
    titleLetters:{
        fontSize: '1.5rem',
        fontWeight: 'bold',
        textAlign: 'center',
        fontFamily: 'Montserrat, sans-serif',
    },
    modal: {
        position: 'absolute',
        overflow: 'hidden',
        maxWidth: '700px',
        // outline: 0,
        placeItems: 'center',
    },
    formContainer: {
        position: 'absolute',
        outline: 0,
        left: '2vh',
        maxHeight: '90vh',
        maxWidth: '90vw',
        marginTop: '5vh',
        minHeight: '50vh',
    },
    modalTitle:{
        fontFamily: 'Montserrat, sans-serif',
    },
    instIcon:{
        marginRight: '10px',
    },
    clearGrid: {
        // background: 'red',
    },
    "@keyframes aboutTransition":{
        from:{
          transform: 'rotateX(90deg)',
          opacity: 0
        },
        to:{
          transform: 'rotateX(0deg)',
          opacity: 1,
        }
      },
    // buttonContainer: {
    //     position: 'absolute',
    //     bottom: '5vh',
    //     background: 'black',
    // },
}))

export function Maingrid(props) {
    const classes = useStyles();
    // const checked = useWindowPosition('about', -1);
    // const [ checked, setChecked] = useState(false);
    // useEffect(() =>{
    //     setChecked(true);
    // })

    // const [open, setOpenSignUp] = React.useState(false);
    // const [openSurvey, setOpenSurvey] = React.useState(false);
    // const [openContact, setOpenContact] = React.useState(false);

    // const handleOpenSignUp = () => {
    //     setOpenSignUp(true);
    // };
    // const handleCloseSignUp = () => {
    //     setOpenSignUp(false);
    // };
    // const handleOpenSurvey = () => {
    //     setOpenSurvey(true);
    // };
    // const handleCloseSurvey = () => {
    //     setOpenSurvey(false);
    // };

    // const handleOpenContact = () => {
    //     setOpenContact(true);
    // };
    // const handleCloseContact = () => {
    //     setOpenContact(false);
    // };



    return (
        <>
            <div className={classes.gridBar}>
                <Grid container spacing={3} className={classes.mainGrid}>
                    <Grid item xs={12} sm={5} className={classes.gridSpace}></Grid>
                    <Grid id="actualAbout" item sm={12} md={12} lg={7} className={clsx(classes.gridItem)}>
                        <Paper elevation={0} className={clsx(classes.paper, classes.actualAbout)}> 
                            <br/>
                            <div className={classes.titleLetters}>
                                    What we're working on!
                                </div>
                            <br/>
                            <div className={classes.paragraphText}>
                                Growing up I constantly sought out characters in books, 
                                movies and TV that looked like me. Do they have a darker 
                                complexion? Have a different name? Come from another culture? 
                                I was often disappointed and would settle for the character 
                                that didn't fit in. It wasn't any different when it came to careers. 
                                My parents being immigrants wanted to make sure we developed skills 
                                that would be highly valuable. My dad would often say, "everyone 
                                needs an accountant", or "engineers always have so many opportunities 
                                lined up for them". The only professions I knew about were the traditional 
                                ones. I knew people had "desk jobs" but I didn't really understand what 
                                they were since both my parents worked in factories.
                                <br/><br/>
                                Now that I am grown up (although adulting is still hard), I often wonder 
                                where my life would have taken me if I had been exposed to different 
                                professions and had seen people who look like me in non-traditional professions. 
                                <br/><br/>
                                <div className={classes.boldLetters}>
                                    Imagine if you could see yourself... doing anything!
                                </div>
                                <br/><br/>
                                We're developing an online stories series where your children can learn
                                about different careers in a wide range of fields. But the coolest part 
                                is they get to build characters who resemble them and their loved ones. 
                                We believe representation matters. Giving children the opportunity to 
                                explore  careers and see themselves represented in the stories they 
                                read can make all the difference.


                                 <br/><br/>
                                <div className={classes.boldLetters}>
                                    Stay tuned for more updates later this year!
                                </div>
                            </div>
                            <br />
                            <Grid container className={classes.buttonGrid}>
                                <Grid item xs={12} lg={3} className={classes.buttonGridItem}>
                                    <Link 
                                        color="inherit" 
                                        underline='none'
                                        onClick={()=> window.open("https://forms.gle/HdyJuWcKQ5ywxmtN7", "_blank")}
                                        >
                                        <Button size="large" className={clsx(classes.button)}> Sign Up </Button>
                                    </Link>
                                    {/* <Modal
                                        aria-labelledby="transition-modal-title"
                                        aria-describedby="transition-modal-description"
                                        className={classes.modal}
                                        open={open}
                                        onClose={handleCloseSignUp}
                                        closeAfterTransition
                                        BackdropComponent={Backdrop}
                                        BackdropProps={{
                                        timeout: 500,
                                        }}
                                    >
                                        <Fade in={open}>
                                        <div className={classes.paper}>
                                            <iframe 
                                                title="Sign Up Form"
                                                className={classes.formContainer} 
                                                src="https://docs.google.com/forms/d/e/1FAIpQLSerNk-2AL-AvBDg3eqhtgBb16Pn2X_QvavTMvZUOnb2d4B8mg/viewform?embedded=true" 
                                                width="640" 
                                                height="911" 
                                                frameborder="0" 
                                                marginheight="0" 
                                                marginwidth="0">Loading…
                                            </iframe>
                                            <Grid container className={classes.clearGrid}>
                                                <Grid item xs={10}></Grid>
                                                <Grid item xs={2} className={classes.clearGridItem}>
                                                    <Button className={classes.button} onClick={handleCloseSignUp}>
                                                        <ClearIcon className={classes.clear}/>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        </Fade>
                                    </Modal> */}
                                </Grid>
                                <Grid item xs={12} lg={3} className={classes.buttonGridItem}>
                                    <Link 
                                        color="inherit" 
                                        underline='none'
                                        onClick={()=> window.open("https://forms.gle/2xhdVQpEtBPV3gMN7", "_blank")}
                                        >
                                        <Button size="large" className={clsx(classes.button)}> Take our Survey </Button>
                                    </Link>
                                    {/* <Modal
                                        aria-labelledby="transition-modal-title"
                                        aria-describedby="transition-modal-description"
                                        className={classes.modal}
                                        open={openSurvey}
                                        onClose={handleCloseSurvey}
                                        closeAfterTransition
                                        BackdropComponent={Backdrop}
                                        BackdropProps={{
                                        timeout: 500,
                                        }}
                                    >
                                        <Fade in={openSurvey}>
                                        <div className={classes.paper}>
                                            <iframe 
                                                title="Survey Form"
                                                className={classes.formContainer} 
                                                src="https://docs.google.com/forms/d/e/1FAIpQLSfFdQywZYdy7PCQM3g7iTFVrqwwHdXUrRL06P8B4MAAyG95Ww/viewform?embedded=true" 
                                                width="640" 
                                                height="2526" 
                                                frameborder="0" 
                                                marginheight="0" 
                                                marginwidth="0">Loading…
                                            </iframe>
                                            <Grid container className={classes.clearGrid}>
                                                <Grid item xs={10}></Grid>
                                                <Grid item xs={2} className={classes.clearGridItem}>
                                                    <Button className={classes.button} onClick={handleCloseSurvey}>
                                                        <ClearIcon className={classes.clear}/>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        </Fade>
                                    </Modal> */}
                                </Grid>
                                <Grid item xs={12} lg={3} className={classes.buttonGridItem}>
                                    <Link 
                                        color="inherit" 
                                        underline='none'
                                        onClick={()=> window.open("https://forms.gle/eTgmoByGxUwkoSsd9", "_blank")}
                                        >
                                        <Button size="large" className={clsx(classes.button)}> Contact Us </Button>
                                    </Link>
                                    {/* <Modal
                                        aria-labelledby="transition-modal-title"
                                        aria-describedby="transition-modal-description"
                                        className={classes.modal}
                                        open={openContact}
                                        onClose={handleCloseContact}
                                        closeAfterTransition
                                        BackdropComponent={Backdrop}
                                        BackdropProps={{
                                        timeout: 500,
                                        }}
                                    >
                                        <Fade in={openContact}>
                                        <div className={classes.paper}>
                                            <div className={classes.modalSpace}></div>
                                            <iframe 
                                                title="Contact Form"
                                                className={classes.formContainer}
                                                src="https://docs.google.com/forms/d/e/1FAIpQLScZcYruAcK-I1J__a_p0cK9olZ521Ms5EODX8RAa3J_uecRqA/viewform?embedded=true" 
                                                width="640"
                                                height="2526"
                                                frameborder="0"
                                                marginheight="0"
                                                marginwidth="0">
                                                    Loading…
                                                </iframe>
                                                <Grid container className={classes.clearGrid}>
                                                    <Grid item xs={10}></Grid>
                                                    <Grid item xs={2} className={classes.clearGridItem}>
                                                        <Button className={classes.button} onClick={handleCloseContact}>
                                                            <ClearIcon className={classes.clear}/>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Fade>
                                    </Modal> */}
                                </Grid>
                                <Grid item xs={12} lg={3} className={classes.buttonGridItem}>
                                    <Link 
                                        color="inherit" 
                                        underline='none'
                                        onClick={()=> window.open("https://www.instagram.com/seeyourselfreading/", "_blank")}
                                        >
                                        <Button size="large" className={clsx(classes.button)}> <InstagramIcon  className={classes.instIcon}/> Follow Us </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
