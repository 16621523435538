import React from "react";
import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import Header from './components/Header';
import { ReactComponent as Wave} from "./img/wave_blue.svg";
import { ReactComponent as Sub} from "./img/submarine_shadow.svg";
import clsx from 'clsx';
import { Maingrid } from "./components/MainGrid";
import Intro from "./components/intro";




const useStyles = makeStyles((theme) => ({
  root:{
    minHeight: '100vh',
    maxHeight: '100vh',
    overflow: 'hidden',
    justifyContent: "center",
    boxSizing: "border-box",
    position: "relative",
    boarderStyle: "none",
    flexGrow: 1,
  },
  behind:{
    [theme.breakpoints.up('xs')]:{
      // background: 'black',
      position: "absolute",
      width: '250%',
      height: "250%",
      justifyContent: 'center',
      left: '-75vw',
      // marginTop: '0vh',
      top: '-10vh',
      animation: '$divMotion 4s ease-in-out infinite alternate',
    },
    [theme.breakpoints.down('xs')]:{
      background: 'none',
      position: "absolute",
      width: '300%',
      height: "300%",
      // justifyContent: 'center',
      left: '-75vw',
      top: '-80vw',
      // top: '-10vh',
      animation: '$divMotion 6s ease-in-out infinite alternate',
    },
    backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/static_background.png"})`,
    backgroundSize: '110%',
    backgroundPosition: 'center',
    backgroundRepeat:"no-repeat",
    zIndex: '-1',
  },
  waveOuter:{
    zIndex:'-1',
  },
  background:{
    minHeight: '100vh',
    borderStyle: "none",
    backgroundSize: '110%',
    backgroundPosition: 'center',
    backgroundRepeat:"no-repeat",
    justifyContent: 'center',
    // opacity: "0.8",
  },
  actualBackground:{
    position: 'absolute',
    top: 0,
    left: 0,
    minHeight: '100vh',
    maxHeight: '100vh',
    width: '100%',
    background: '#4C99C9',
    backgroundSize: '110%',
    backgroundPosition: 'center',
    backgroundRepeat:"no-repeat",
    zIndex: '-1',
  },
  foreGround:{
    marginTop: '-100vh',
    zIndex: '2',
  },
  // logoParent:{
  //   display:'grid',
  //   gridTemplate
  // }
  logoGrid:{
    [theme.breakpoints.up('xs')]:{
      padding: theme.spacing(7),
    },
    [theme.breakpoints.down('xs')]:{
      padding: theme.spacing(7),
    }
  },
  overGrid: {
    width: '100vw',
    // background: '#527692',
  },
  // mainGrid:{
  //   width: '100vw',
  //   margin: 'auto',
  // },
  logoContainer:{
    [theme.breakpoints.up('xs')]:{
      minWidth: '400px',
      minHeight: '40vh',
    },
    [theme.breakpoints.down('xs')]:{
      minWidth: '50px',
    },
    padding: theme.spacing(2),
    position: "relative",
    // background: 'chocolate',
  },
  emptyLogoDiv:{
    padding: theme.spacing(2),
    // background: 'blue',
  },
  arrowContainer:{
    // background: "green",
    minHeight: '0vh',
  },
  spacing: {
    marginTop: "30vh",
    minHeight: '25vh',
  },
  sub:{
    [theme.breakpoints.up('sm')]:{
      height: '30vw',
      left: '35%',
      top: '10%',
    },
    [theme.breakpoints.down('sm')]:{
      height: '30vw',
      left: '35%',
      top: '10vh',
    },
    [theme.breakpoints.down('xs')]:{
      height: '40vw',
      top:"50vh",
      left: "33%",
    },
    position: 'absolute',
    transform: 'translate(-50%,-50%)',
    transformOrigin: 'center',
    animation: '$subMotion 4s ease-in-out infinite alternate'
  },
  // waves:{
  //   opacity: 0.7,
  // },
  wave1:{
    [theme.breakpoints.up('sm')]:{
      top: "0vh",
      left: "0vh",
    },
    [theme.breakpoints.down('sm')]:{
      top:"-20vh",
      left: "0vh",
    },
    [theme.breakpoints.down('xs')]:{
      top:"-30vh",
      left: "0vh",
    },
    position: "absolute",
    animation: '$waveMotion 10s ease-in-out infinite alternate',
  },
  wave2:{
    [theme.breakpoints.up('sm')]:{
      top: "-25vh",
      left: "15vw",
    },
    [theme.breakpoints.down('sm')]:{
      top: "-35vh",
      left: "15vw",
    },
    [theme.breakpoints.down('xs')]:{
      top: "-42vh",
      left: "15vw",
    },
    position: "absolute",
    animation: '$waveMotion 7s ease-in-out infinite alternate-reverse',
  },
  wave3:{
    [theme.breakpoints.up('sm')]:{
      top: "-30vh",
      left: "0vh",
    },
    [theme.breakpoints.down('sm')]:{
      top: "-35vh",
      left: "0vh",
    },
    [theme.breakpoints.down('xs')]:{
      top: "-38vh",
      left: "0vh",
    },
    position: "absolute",
    animation: '$waveMotion 9s ease-in-out infinite alternate',
  },
  wave4:{
    [theme.breakpoints.up('sm')]:{
      top: "-55vh",
      left: "15vw",
    },
    [theme.breakpoints.down('sm')]:{
      top: "-50vh",
      left: "15vw",
    },
    [theme.breakpoints.down('xs')]:{
      top: "-52vh",
      left: "15vw",
    },
    position: "absolute",
    animation: '$waveMotion 10s ease-in-out infinite alternate-reverse',
  },
  wave5:{
    [theme.breakpoints.up('sm')]:{
      top: "-60vh",
      left: "0vh",
    },
    [theme.breakpoints.down('sm')]:{
      top: "-50vh",
      left: "0vh",
    },
    [theme.breakpoints.down('xs')]:{
      top: "-48vh",
      left: "0vh",
    },
    position: "absolute",
    animation: '$waveMotion 6s ease-in-out infinite alternate',
  },
  "@keyframes waveMotion":{
    from:{
      transform: 'translateX(0%)'
    },
    to:{
      transform: 'translateX(4%)'
    }
  },
  "@keyframes divMotion":{
    from:{
      transform: 'translateY(0%)'
    },
    to:{
      transform: 'translateY(1%)'
    }
  },
  "@keyframes subMotion":{
    from:{
      transform: 'rotateZ(355deg)'
    },
    to:{
      transform: 'rotateZ(365deg)'
    }
  },
}));

function App() {
  const classes = useStyles();


  return (
      <div className={classes.root}>
        <Header />
        <div className={classes.actualBackground}>
              <div 
              className={classes.waveOuter}
              // style={{ transform: `translateY(${offsetY * 0.5}px)`}}
              >
                <div className={classes.behind}>
                  <Wave className={clsx(classes.wave5, classes.waves)} />
                  <Wave className={clsx(classes.wave4, classes.waves)} />
                  <Wave className={clsx(classes.wave3, classes.waves)} />
                  <Sub className={classes.sub} />
                  <Wave className={clsx(classes.wave2, classes.waves)} />
                  <Wave className={clsx(classes.wave1, classes.waves)} />
                </div>
              </div>
          <Intro />
        </div>
        <div className={classes.foreGround}>
              <div 
                className={classes.mainGrid}>
                <Maingrid/>
              </div>
        </div>
      </div>
  );
}

export default App;
